'use client'
import classNames from 'classnames';
import { useEffect, useState } from 'react';


export default function Template({ children }: { children: React.ReactNode }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    setIsVisible(true);
    setTimeout(() => {
      setIsMounted(false);
    }, 1000);
  }, []);
  const classes = classNames('transition-all relative lg:pb-20 w-full overflow-hidden', {
    'duration-500': isMounted,
    'opacity-100 top-0': isVisible,
    'opacity-0 top-[50px]': !isVisible
  });

  return <>
    <div className={classes}>
      {children}
    </div>
  </>
}